<template>
  <div class="commission-popup">
    <div class="invoice-item" v-for="(invoice, index) in invoices" :key="index">
      <div><strong>Invoice ID: </strong>{{ invoice.invoice_quickbook_id }}</div>
      <div><strong>Invoice Payment Date: </strong>{{ invoice.invoice_payment_date }}</div>
      <div><strong>Client: </strong>{{ invoice.client.name }}</div>
      <div><strong>Project: </strong>{{ invoice.project.name }}</div>
      <div><strong>Amount: </strong>{{ invoice.amount }}</div>
      <div><strong>Commission Percent: </strong>{{ invoice.commission_percent }}</div>
      <div><strong>Commission: </strong>{{ invoice.commission }}</div>
    </div>
  </div>
</template>
<script>
import MtApi from '@/agGridV2/helpers/mt-api.helper'

export default {
  data() {
    return {
      invoices: []
    }
  },
  async mounted() {
    this.invoices = await MtApi.getCommissionsAnalyticsInvoices({ month: this.$attrs.month, staff_id: this.$attrs.staff_id })
  }
}
</script>
<style lang="scss" scoped>
.commission-popup {
  margin: 25px;
  display: flex;
  flex-direction: column;
  gap: 20px;
  height: 450px;
}
</style>
