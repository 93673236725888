<template>
  <div class="page-accounting">
    <accounting-navigation />
    <commissions
      activityTagName="account management"
    />
  </div>
</template>

<script>
// Components
import Commissions from '@/agGridV2/components/commissions.component.vue'
import AccountingNavigation from '@/components/AccountingNavigation.vue'

export default {
  components: {
    AccountingNavigation,
    Commissions
  },
  data() {
    return {}
  }
}
</script>
