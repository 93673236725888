<template>
  <div class="refresh-analytics">
    <button :class="['button-outline-blue']" @click="generateCommissions">
      <b-icon icon="arrow-repeat" v-if="!loading"></b-icon>
      <b-spinner v-if="loading" style="width: 14px; height: 14px; border: 0.09em solid currentcolor; border-right-color: transparent;" label="Loading..."></b-spinner>
    </button>
  </div>
</template>

<script>
import MtApi from '@/agGridV2/helpers/mt-api.helper'
import { mapMutations } from 'vuex'

export default {
  name: 'refresh-analytics',
  data() {
    return {
      loading: false
    }
  },
  props: ['type'],
  methods: {
    ...mapMutations(['setViewLoader']),
    async generateCommissions() {
      this.$bvToast.toast('Generation takes about a minute. please wait and do not reload the page!', {
        title: 'Generate Start!',
        variant: 'warning',
        autoHideDelay: 10000,
        solid: true
      })
      this.loading = true
      this.setViewLoader(true)
      try {
        const response = await MtApi.generateAnalytics({ type: this.type })
        this.loading = false
        this.setViewLoader(false)
        this.$bvToast.toast(response, {
          title: 'Generate Success! Please wait for the page to reload!',
          variant: 'success',
          solid: true
        })
        const timeout = setTimeout(() => {
          window.location.reload()
          clearTimeout(timeout)
        }, 1500);
      } catch (err) {
        this.$bvToast.toast(err, {
          title: 'Generate False!',
          variant: 'danger',
          solid: true
        })
        this.setViewLoader(false)
        this.loading = false
      }
    }
  }
}
</script>
